/* eslint-disable react/jsx-props-no-spreading */
import { Box, Divider, Paper, Typography } from "@material-ui/core";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import Layout from "./layout";
import Seo from "./seo";
import "../styles/post-page-template.css";

const components = {
  p: (props) => (
    <Typography variant="body1" style={{ marginBottom: "0.4rem" }} {...props} />
  ),
  h1: (props) => (
    <Typography component="h1" variant="h4" gutterBottom {...props} />
  ),
  h2: (props) => (
    <Typography
      component="h2"
      variant="h6"
      color="textSecondary"
      style={{ marginTop: "1.5rem" }}
      {...props}
    />
  ),
  a: Link,
  blockquote: (props) => (
    <Paper
      elevation={0}
      style={{ borderLeft: "4px solid grey", padding: 10 }}
      {...props}
    />
  ),
  hr: Divider,
  li: (props) => <Typography component="li" {...props} />,
};

export const query = graphql`
  query PostsByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      body
      fields {
        slug
      }
      frontmatter {
        date(formatString: "YYYY.MM")
        public
      }
      headings(depth: h1) {
        value
      }
    }
  }
`;

const postPageTemplate = ({ data }) => {
  const { siteUrl } = data.site.siteMetadata;
  const { fields, frontmatter, body, headings } = data.mdx;
  return (
    <Layout>
      <Seo title={headings[0].value} canonical={siteUrl + fields.slug} />
      <Typography>{frontmatter.date}</Typography>
      <Box m={3} className="post-content">
        {frontmatter.public ? (
          <MDXProvider components={components}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        ) : (
          <Typography>Sorry, this post is set to non-public.</Typography>
        )}
      </Box>
      {/* <Grid
        container
        direction="row-reverse"
        // justify will be deprecated in favor of justifyContent:
        // https://github.com/mui-org/material-ui/pull/21845
        justify="space-between"
        alignItems="center"
      >
        <Link to="/">前一篇</Link>
        <Link to="/">后一篇</Link>
      </Grid> */}
    </Layout>
  );
};

export default postPageTemplate;
